<template>
  <component :is="provider" :index="computedIndex" :ad-slot="adSlot" />
</template>

<script>
import { mapState, mapGetters } from 'vuex';

const Advertserve = () => import('@/components/Ads/Advertserve/Ad.vue');
const Static = () => import('@/components/Ads/Static/Ad.vue');
const Script = () => import('@/components/Ads/Script/Ad.vue');

export default {
  props: {
    adSlot: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapState({
      adProvider: (state) => state.advertisements.provider,
    }),
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    provider() {
      if (this.adProvider === 'advertserve') {
        return Advertserve;
      }
      if (this.adProvider === 'script') {
        return Script;
      }

      return Static;
    },
    computedIndex() {
      if (this.currentDirectory?.add_record_text) {
        return this.index + 1;
      }
      return this.index;
    },
  },
};
</script>
